import React from "react";

import CtaBlock from "../CtaBlock";
import ImageStack from "../ImageStack";
import LogoGrid from "../LogoGrid";
import MediaBlock from "../MediaBlock/MediaBlock";
import Quote from "../Quote";
import RelatedContent from "../RelatedContent";
import Tout from "../Tout";
import FullScreenTout from "../FullScreenTout";
import KeyStats from "../KeyStats";
import FormBlock from "../FormBlock";
import TextBlock from "../TextBlock";
import SimpleText from "../SimpleText";
import BioBlock from "../BioBlock";
import Feed from "../Feed";
import FeaturedPosts from "../FeaturedPosts";

const ComponentComposer = ({ componentName, id, index, ...props }) => {
	const componentList = {
		WordPressAcf_cta_block: CtaBlock,
		WordPressAcf_image_stack: ImageStack,
		WordPressAcf_logo_grid: LogoGrid,
		WordPressAcf_media_block: MediaBlock,
		WordPressAcf_quote: Quote,
		WordPressAcf_related_content: RelatedContent,
		WordPressAcf_tout: Tout,
		WordPressAcf_full_screen_tout: FullScreenTout,
		WordPressAcf_key_stats: KeyStats,
		WordPressAcf_form_block: FormBlock,
		WordPressAcf_text_block: TextBlock,
		WordPressAcf_bio_block: BioBlock,
		WordPressAcf_feed: Feed,
		WordPressAcf_simple_text: SimpleText,
		WordPressAcf_featured_posts: FeaturedPosts
	};
	const ComponentType = componentList[componentName];

	if (!ComponentType) {
		return null;
	}
	return <ComponentType id={id} index={index} {...props} />
};

export default ComponentComposer;