import React from "react";
import { graphql } from "gatsby";

import Container from "../Container";
import Heading from "../Heading";
import ModuleSpacing from "../ModuleSpacing";

import styles from "./FormBlock.module.css";
import { trimParagraphs } from "../../globals/helpers";

const FormBlock = ({ headline, form_subheading, copy, form_snippet, image }) => (
	<section className={styles.formBlock}>
		<Container>
			<ModuleSpacing top>
				<div className={styles.containerInner}>
					<div className={styles.textColumn}>
						<Heading type="h3" text={trimParagraphs(headline)} />
						<div dangerouslySetInnerHTML={{ __html: copy }} />
						{image?.localFile?.url && <img src={image.localFile.url} alt={image.alt_text} />}
					</div>
					<div className={styles.column}>
						<Heading type="h6" text={form_subheading} />
						<div dangerouslySetInnerHTML={{ __html: form_snippet }} />
					</div>
				</div>
			</ModuleSpacing>
		</Container>
	</section>
);

export default FormBlock;

export const query = graphql`
	fragment FormBlockFragment on WordPressAcf_form_block {
		headline
		copy
		form_subheading
		form_snippet
		image {
			alt_text
            localFile {
              url
            }
		}
	}
`;