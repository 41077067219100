import React from "react";
import { Link } from "gatsby";

import { useInView } from 'react-intersection-observer';

import Container from "../Container";
import Cta from "../Cta";
import Heading from "../Heading";
import ModuleSpacing from "../ModuleSpacing";

import { trimParagraphs } from "../../globals/helpers";
import Texture from "../../resources/assets/texture.svg";

import styles from "./Hero.module.css";

const Hero = ({
	categories,
	type,
	image_alignment,
	headline,
	copy,
	cta,
	background_image,
	foreground_texture,
	background_texture,
	fallbackTitle = "",
	hasAcf,
	usesWPContent
}) => {
	const { ref, inView, entry } = useInView({
		threshold: 0.5,
		triggerOnce: true
	});
	const postIsWhitepaper = type ==="post" && categories?.some(category => category.slug.includes("white-papers"));
	const heroAltBackground = type === "post" && categories?.some(category => !category.slug.includes("case-studies"));
	if (!postIsWhitepaper && (headline || (fallbackTitle && !hasAcf))) {
		return (
			<section 
				ref={ref}
				className={`
					${inView ? styles.heroInView : styles.hero}
					${image_alignment ? styles.imageRight : styles.imageLeft}
					${background_image?.localFile ? styles.heroHasImage : ""}
					${heroAltBackground ? styles.heroGray : ""}
				`}
			>
				<Container>
					<ModuleSpacing bottom={false}>
						<div className={!background_image ? styles.fullHeroContainer : styles.heroContainer}>
							<div className={`${styles.column} ${!background_image ? styles.fullTextColumn : styles.textColumn}`}>
								{heroAltBackground && categories?.length && (
									<ul className={styles.categoryList}>
										{categories.map((category, index) => (
											category?.slug && (
												<li key={index}>
													<Link to={`/${category?.slug}`}>{category.name}</Link>
												</li>
											)
										))}
									</ul>
								)}
								<Heading type="h1" level={heroAltBackground ? "3" : "1"} text={trimParagraphs(headline ? headline : fallbackTitle)} />
								{copy && <div className={cta?.url ? styles.heroCopyAddPadding : styles.heroCopy} dangerouslySetInnerHTML={{ __html: copy }} />}
								{cta?.url && <div><Cta href={cta.url} target={cta.target}>{cta.title}</Cta></div>}
							</div>
							{background_image?.localFile && (
								<div className={`${styles.column} ${styles.imageColumn}`}>
									<div className={styles.imageContainer}><img src={background_image?.localFile.url} alt={background_image.alt_text} /></div>
								</div>
							)}
						</div>
					</ModuleSpacing>
					{foreground_texture && foreground_texture !== "None" && <Texture className={styles.texture} />}
				</Container>
				{background_image && !usesWPContent && <div className={styles.extendedBackground} />}
			</section>
		);
	}
	return <></>;
};

export default Hero;