import React, { useContext } from "react"
import { graphql, Link } from "gatsby"
import PostContext from "../../context/PostContext";

import Container from "../Container"
import CategoryEyebrow from "../CategoryEyebrow"
import ImageBlock from "../ImageBlock"
import ModuleSpacing from "../ModuleSpacing"

import ArrowIcon from "../../resources/assets/arrow.svg"

import { trimParagraphs, generatePostPath } from "../../globals/helpers"
import Texture from "../../resources/assets/texture.svg"

import styles from "./FeaturedPosts.module.css"

const Post = ({
  post_name,
  post_title,
  excerpt,
  categories,
  featured_media,
}) => {
  const categoryPath = generatePostPath(categories);
  return (
    <div className={styles.post}>
      {featured_media?.localFile?.url && (
        <Link to={`/${categoryPath}/${post_name}`} className={styles.imageWrapper}>
          <img
            src={featured_media.localFile.url}
            alt={featured_media?.alt_text}
          />
        </Link>
      )}
      {categories?.length && (
        <div className={styles.categories}>
          <CategoryEyebrow color="Yellow" categories={categories} />
        </div>
      )}
      <Link to={`/${categoryPath}/${post_name}`} className={styles.postTitle}>
        <strong>{post_title}</strong>
      </Link>
      <div
        className={styles.excerpt}
        dangerouslySetInnerHTML={{ __html: excerpt }}
      />
      <Link to={`/${categoryPath}/${post_name}`} className={styles.readMore}>
        Read More <ArrowIcon />
      </Link>
    </div>
  )
}

const RelatedContent = ({ featured_posts }) => {
	const postData = useContext(PostContext);
  return (
    <section className={styles.featuredPosts}>
      <Container>
        <ModuleSpacing top>          
            {featured_posts?.length && (
				<div className={styles.posts}>
					{featured_posts.map((item, index) => {
						const filteredPost = postData?.edges.find(
							post => post.node.wordpress_id === item.wordpress_id
						)
						return (
							<ImageBlock
								key={index}
								external_link={filteredPost?.node?.acf?.external_link}
								og_image={filteredPost?.node?.acf?.og_image_link}
								title={filteredPost?.node?.title}
								slug={filteredPost?.node?.slug}
								featured_media={filteredPost?.node?.featured_media}
								categories={filteredPost?.node?.categories} 
							/>
							)
						})}
				</div>
			)}
        </ModuleSpacing>
      </Container>
    </section>
  )
}

export default RelatedContent

export const query = graphql`
  fragment FeaturedPostsFragment on WordPressAcf_featured_posts {
    featured_posts {
      guid
      description
      post_date
      post_content
      post_name
      post_title
      post_excerpt
      wordpress_id
    }
  }
`
