import React, { useState, useContext } from "react"
import { useKeenSlider } from "keen-slider/react"
import { graphql, Link } from "gatsby"

import PostContext from "../../context/PostContext"

import Container from "../Container"
import CategoryEyebrow from "../CategoryEyebrow"
import Cta from "../Cta"
import Eyebrow from "../Eyebrow"
import Heading from "../Heading"
import ModuleSpacing from "../ModuleSpacing"

import ArrowIcon from "../../resources/assets/arrow.svg"

import { trimParagraphs, generatePostPath } from "../../globals/helpers"
import Texture from "../../resources/assets/texture.svg"

import styles from "./RelatedContent.module.css"

const Post = ({
  post_name,
  post_title,
  excerpt,
  categories,
  featured_media,
  external_link,
  og_image
}) => {
  const categoryPath = generatePostPath(categories)
  return (
    <div className={styles.post}>
      {(featured_media?.localFile?.url || og_image) && (
        <Link
          to={external_link ? external_link : `/${categoryPath}/${post_name}`}
          className={styles.imageWrapper}
		  target={external_link ? "_blank" : "same"}
        >
          <img
            src={og_image ? og_image : featured_media.localFile.url}
            alt={featured_media?.alt_text}
          />
        </Link>
      )}
      {categories?.length && (
        <div className={styles.categories}>
          <CategoryEyebrow color="Yellow" categories={categories} />
        </div>
      )}
      <Link to={external_link ? external_link : `/${categoryPath}/${post_name}`} target={external_link ? "_blank" : "same"} className={styles.postTitle}>
        <strong>{post_title}</strong>
      </Link>
      <div
        className={styles.excerpt}
        dangerouslySetInnerHTML={{ __html: excerpt }}
      />
      <Link to={external_link ? external_link : `/${categoryPath}/${post_name}`} target={external_link ? "_blank" : "same"} className={styles.readMore}>
        Read More <ArrowIcon />
      </Link>
    </div>
  )
}

const RelatedContent = ({
  previousComponentName,
  copy,
  cta,
  eyebrow,
  headline,
  featured_posts,
  texture,
}) => {
	const postData = useContext(PostContext)

  const [currentSlide, setCurrentSlide] = useState(0)
  const [sliderRef, postSlider] = useKeenSlider({
    initial: 0,
    slidesPerView: 1,
    mode: "snap",
    autoHeight: true,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide)
    },
  })

  return (
    <section
      className={`${styles.relatedContent} ${
        previousComponentName === "WordPressAcf_tout"
          ? styles.extendBackground
          : ""
      }`}
    >
      <Container>
        <ModuleSpacing top>
          <div className={!headline ? styles.split : ""}>
            {eyebrow && <Eyebrow>{eyebrow}</Eyebrow>}
            {headline && <Heading type="h2" text={trimParagraphs(headline)} />}
            {copy && (
              <div
                className={styles.copy}
                dangerouslySetInnerHTML={{ __html: copy }}
              />
            )}
            {cta?.url && (
              <div className={styles.headerCta}>
                <Cta href={cta.url} target={cta.target}>
                  {cta.title}
                </Cta>
              </div>
            )}
          </div>

          {featured_posts?.length &&
          typeof window !== "undefined" &&
          window.innerWidth < 640 ? (
            <>
              <div className={styles.imageCarouselContainer} ref={sliderRef}>
                {featured_posts?.length &&
                  featured_posts.map((item, index) => {
                    const filteredPost = postData?.edges.find(
                      post => post.node.wordpress_id === item.wordpress_id
                    )
                    return (
                      <div
                        key={index}
                        className={`${styles.post} keen-slider__slide`}
                      >
                        <Post
                          guid={item.guid}
                          post_title={item.post_title}
                          post_name={item.post_name}
                          excerpt={filteredPost?.node.excerpt}
                          categories={filteredPost?.node.categories}
                          featured_media={filteredPost?.node.featured_media}
						  external_link={filteredPost?.node?.acf.external_link}
						  og_image={filteredPost?.node?.acf.og_image_link}	  
                        />
                      </div>
                    )
                  })}
              </div>
              {postSlider && featured_posts?.length > 1 && (
                <div className={styles.imageDots}>
                  {[...Array(featured_posts.length)].map((_, index) => (
                    <button
                      key={index}
                      onClick={() => {
                        postSlider.moveToSlideRelative(index)
                      }}
                      className={
                        styles[
                          `${currentSlide === index ? "dotActive" : "dot"}`
                        ]
                      }
                    />
                  ))}
                </div>
              )}
            </>
          ) : (
            <div className={styles.posts}>
              {featured_posts.map((item, index) => {
                const filteredPost = postData?.edges.find(
                  post => post.node.wordpress_id === item.wordpress_id
                )
                return (
                  <Post
                    key={index}
                    guid={item.guid}
                    post_title={item.post_title}
                    post_name={item.post_name}
                    excerpt={filteredPost?.node.excerpt}
                    categories={filteredPost?.node.categories}
                    featured_media={filteredPost?.node.featured_media}
					external_link={filteredPost?.node?.acf.external_link}
					og_image={filteredPost?.node?.acf.og_image_link}
                  />
                )
              })}
            </div>
          )}
          {cta?.url && (
            <div className={styles.footerCta}>
              <Cta href={cta.url} target={cta.target}>
                {cta.title}
              </Cta>
            </div>
          )}
        </ModuleSpacing>
        {texture && <Texture className={styles.texture} />}
      </Container>
    </section>
  )
}

export default RelatedContent

export const query = graphql`
  fragment RelatedContentFragment on WordPressAcf_related_content {
    copy
    cta {
      target
      title
      url
    }
    eyebrow
    headline
    featured_posts {
      guid
      description
      post_date
      post_content
      post_name
      post_title
      post_excerpt
      wordpress_id
    }
    texture
    id
  }
`
