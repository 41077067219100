import React from "react"
import { graphql } from "gatsby"

import Container from "../Container"
import Heading from "../Heading"
import ModuleSpacing from "../ModuleSpacing"

import { trimParagraphs } from "../../globals/helpers"

import styles from "./KeyStats.module.css"

const KeyStats = ({ background_color = "white", copy, headline = "", stats, type }) => (
  <section
    className={`${type === "image" ? styles.keyStatsImage : styles.keyStatsMetric} ${styles[`background${background_color}`]}`}
  >
    <Container>
      <ModuleSpacing top>
        <div className={styles.innerContainer}>
          <div className={styles.header}>
            <Heading type="h4" text={trimParagraphs(headline)} />
            <div
              className={styles.copy}
              dangerouslySetInnerHTML={{ __html: copy }}
            />
          </div>
          <div className={styles.stats}>
            {stats?.length &&
              stats.map((stat, index) => (
                <div key={index} className={styles.stat}>
					<div className={styles.imageContainer}>
						{stat?.image?.localFile?.url && (
							<img
							src={stat.image.localFile.url}
							alt={stat.image.alt_text}
							/>
						)}
					</div>
                  {type === "metric" && (
                    <>
                      {stat.headline && (
                        <Heading
                          type="h2"
                          text={trimParagraphs(stat.headline)}
                        />
                      )}
                      {stat.copy && (
                        <div
                          className={styles.copy}
                          dangerouslySetInnerHTML={{ __html: stat.copy }}
                        />
                      )}
                    </>
                  )}
                </div>
              ))}
          </div>
        </div>
      </ModuleSpacing>
    </Container>
  </section>
)

export default KeyStats

export const query = graphql`
  fragment KeyStatsFragment on WordPressAcf_key_stats {
	background_color
    copy
    headline
    id
    stats {
      image {
        alt_text
        localFile {
          url
        }
      }
      headline
      copy
    }
    type
  }
`
