import React, { useState, useContext } from "react";
import { graphql } from "gatsby";
import { useKeenSlider } from "keen-slider/react";
import PostContext from "../../context/PostContext";
import TextTruncate from "react-text-truncate";
import "keen-slider/keen-slider.min.css";

import { Link } from "gatsby";

import { useInView } from 'react-intersection-observer';

import ArrowIcon from "../../resources/assets/arrow.svg"

import Container from "../Container";
import Cta from "../Cta";
import Eyebrow from "../Eyebrow";
import Heading from "../Heading";
import ModuleSpacing from "../ModuleSpacing";

import { trimParagraphs, generatePostPath } from "../../globals/helpers"
import Texture from "../../resources/assets/texture.svg";

import styles from "./Tout.module.css";

const Tout = ({
	background_color,
	cta,
	copy,
	headline,
	image_alignment,
	images,
	eyebrow,
	type,
	related_posts_eyebrow,
	related_posts,
	texture,
	index
}) => {
	const postData = useContext(PostContext);
	const { ref, inView, entry } = useInView({
		threshold: typeof window !== 'undefined' && window.innerWidth > 1120 ? 0.5 : 0.2,
		triggerOnce: true
	});
	const [currentTextSlide, setCurrentTextSlide] = useState(0);
	const [textSliderRef, textSlider] = useKeenSlider({
		initial: 0,
		slidesPerView: 1,
		mode: "snap",
		autoHeight: true,
		slideChanged(s) {
			setCurrentTextSlide(s.details().relativeSlide);
		}
	});

	const [currentImageSlide, setCurrentImageSlide] = useState(0);
	const [imageSliderRef, imageSlider] = useKeenSlider({
		initial: 0,
		slidesPerView: 1,
		mode: "snap",
		autoHeight: true,
		slideChanged(s) {
			setCurrentImageSlide(s.details().relativeSlide);
		}
	});

	return (
		<section
			ref={ref}
			className={`
				${inView ? styles.toutInView : styles.tout}
				${styles[`background${background_color}`]}
				${image_alignment ? styles.imageRight : styles.imageLeft}
			`}
		>
			<Container>
				<ModuleSpacing bottom={false} right={image_alignment} left={!image_alignment}>
					<div className={styles.toutContainer}>
						<div className={`${styles.column} ${styles.textColumn}`}>
							<ModuleSpacing top right={!image_alignment} left={image_alignment}>
								{eyebrow && <div className={styles.eyebrow}><Eyebrow>{eyebrow}</Eyebrow></div>}
								<Heading type="h3" text={trimParagraphs(headline)}/>
								<div className={styles.copy} dangerouslySetInnerHTML={{ __html: copy }} />
								{cta?.url && <div><Cta href={cta.url} target={cta.target}>{cta.title}</Cta></div>}
								{texture && <Texture className={styles.texture} />}
								{related_posts && (
									<div className={styles.carouselContainer}>
										{textSlider && related_posts?.length > 1 && (
											<div className={styles.dots}>
											<Eyebrow>{related_posts_eyebrow}</Eyebrow>
											{[...Array(related_posts.length)].map((_, index) => (
													<button
														key={index}
														onClick={() => {
															textSlider.moveToSlideRelative(index);
														}}
														className={styles[`${(currentTextSlide === index ? "dotActive" : "dot")}`]}
													/>
												)
											)}
											</div>
										)}
										<div className={styles.textCarouselContainer} ref={textSliderRef}>
											{related_posts.map((item, index) => {
												const filteredPost = postData?.edges.find(
													post => post.node.wordpress_id === item.wordpress_id
												)
												const categoryPath = generatePostPath(filteredPost?.node?.categories);
												return (
													<div key={index} className={`${styles.slide} keen-slider__slide`}>
														<div className={styles.textCarousel}>
															<strong><Link to={`/${categoryPath}/${item.post_name}`}>{item.post_title}</Link></strong>
															<TextTruncate line={3} element="div" truncateText="…" text={item.post_excerpt} />
															<Link to={`/${categoryPath}/${item.post_name}`} className={styles.readMore}>Read More <ArrowIcon /></Link>
														</div>
													</div>
											)})}
										</div>
									</div>
								)}
							</ModuleSpacing>
						</div>
						<div className={`${styles.column} ${styles.imageColumn}`}>
							<ModuleSpacing left={false} right={false} top={true}>
								<div className={styles.imageCarouselContainer} ref={imageSliderRef}>
									{images?.length && images.map((image, index) => (
										<div key={index} className="keen-slider__slide"><img src={image.localFile.url} alt={image.alt_text} /></div>
									))}
								</div>
								{imageSlider && images?.length > 1 && (
									<div className={styles.imageDots}>
									{[...Array(images.length)].map((_, index) => (
											<button
												key={index}
												onClick={() => {
													imageSlider.moveToSlideRelative(index);
												}}
												className={styles[`${(currentImageSlide === index ? "dotActive" : "dot")}`]}
											/>
										)
									)}
									</div>
								)}
							</ModuleSpacing>
						</div>
					</div>
				</ModuleSpacing>
			</Container>
		</section>
	);
};

export default Tout;

export const query = graphql`
	fragment ToutFragment on WordPressAcf_tout {
		background_color
		cta {
			target
			title
			url
		}
		copy
		eyebrow
		headline
		images {
			alt_text
			localFile {
				url
			}
		}
		image_alignment
		type
		related_posts_eyebrow
		related_posts {
			post_excerpt
			post_title
			guid
			post_name
			wordpress_id
		}
		texture
  }
`;