import React, { useContext, useState } from "react";
import { graphql } from "gatsby";

import PostContext from "../../context/PostContext";

import Container from "../Container";
import Heading from "../Heading";
import ImageBlock from "../ImageBlock";
import ModuleSpacing from "../ModuleSpacing";

import styles from "./Feed.module.css";
import Cta from "../Cta";

const Feed = ({
	headline,
	previousComponentName,
	category = {},
	show_featured_posts,
	use_pagination,
	number_of_posts,
	view_more_link
}) => {
	const [ currentPage, setCurrentPage ] = useState(isNaN(parseInt(number_of_posts, 10)) ? posts.length : parseInt(number_of_posts, 10));
	const postData = useContext(PostContext);
	const filteredPosts = postData.edges.filter(post => post.node.categories.find(postCategory => postCategory.slug === category?.slug));
	let featuredPosts = [];
	let posts = [];
	filteredPosts.filter(post => {
		if (show_featured_posts && post.node.tags && featuredPosts.length < 2) {
			post.node.tags.find(tag => tag.slug === "featured" && featuredPosts.push(post.node));
			return;
		}
		posts.push(post.node);
		return;
	});
	return (
		<section className={styles.feed}>
			<Container>
				<ModuleSpacing top={previousComponentName !== "WordPressAcf_feed"}>
					<div className={styles.heading}><Heading type="h4">{headline ? headline : category?.name}</Heading>{view_more_link?.url && <Cta href={view_more_link.url} target={view_more_link.target}>{view_more_link.title}</Cta>}</div>
					<div className={styles.featuredPosts}>
						{featuredPosts.map((post, index) => (
							<ImageBlock
								key={index}
								external_link={post?.acf?.external_link}
								og_image={post?.acf?.og_image_link}
								size="large"
								title={post.title}
								slug={post.slug}
								featured_media={post.featured_media}
								categories={post.categories}
							/>
						))}
					</div>
					<div className={styles.posts}>
						{posts.slice(0, currentPage).map((post, index) => (
							<ImageBlock
								key={index}
								external_link={post?.acf?.external_link}
								og_image={post?.acf?.og_image_link}
								title={post.title}
								slug={post.slug}
								featured_media={post.featured_media}
								categories={post?.categories} 
							/>
						))}
					</div>
					{use_pagination && (currentPage < posts.length) && <div className={styles.loadMore}><Cta type="button" onClick={() => setCurrentPage(currentPage + parseInt(number_of_posts, 10))}>Load More</Cta></div>}
				</ModuleSpacing>
			</Container>
		</section>
	);
};

export default Feed;

export const query = graphql`
	fragment FeedFragment on WordPressAcf_feed {
		headline
		category {
			term_id
			name
			slug
			term_group
			term_taxonomy_id
			taxonomy
			description
			wordpress_parent
			count
			filter
		}
		show_featured_posts
		number_of_posts
		use_pagination
		view_more_link {
			target
			title
			url
		}
	}
`;