import React from "react";
import { Link } from "gatsby";

import styles from "./Cta.module.css";

const Cta = ({ type, onClick, href, target, children }) => (
	<>
		{type === "button" ?
			<button className={styles.ctaButton} onClick={onClick}>{children}</button>
			:
			<Link className={styles.ctaLink} to={href} target={target}>{children}</Link>
		}
	</>
);

export default Cta;