import React from "react"
import { graphql } from "gatsby"

import Container from "../Container"
import Cta from "../Cta"
import Eyebrow from "../Eyebrow"
import Heading from "../Heading"
import ModuleSpacing from "../ModuleSpacing"

import { trimParagraphs } from "../../globals/helpers"

import styles from "./FullScreenTout.module.css"

const FullScreenTout = ({
  background_image,
  cta,
  copy,
  eyebrow_text,
  headline,
}) => (
  <section className={styles.fullScreenTout}>
    <Container>
      <ModuleSpacing bottom={false}>
        <div className={styles.toutContainer} style={{ backgroundImage: `url(${background_image?.localFile?.url})`}}>
          <ModuleSpacing top>
			  {eyebrow_text && <div className={styles.eyebrow}><Eyebrow>{eyebrow_text}</Eyebrow></div>}
              <Heading type="h3" text={trimParagraphs(headline)} />
              <div
                className={styles.copy}
                dangerouslySetInnerHTML={{ __html: copy }}
              />
              {cta?.url && <Cta href={cta.url} target={cta.target}>{cta.title}</Cta>}
          </ModuleSpacing>
        </div>
      </ModuleSpacing>
    </Container>
  </section>
)

export default FullScreenTout

export const query = graphql`
  fragment FullScreenToutFragment on WordPressAcf_full_screen_tout {
    background_image {
      alt_text
      localFile {
        url
      }
    }
    eyebrow_text
    headline
    cta {
      target
      title
      url
    }
  }
`
