import React, { useState } from "react";
import { graphql } from "gatsby";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";

import Container from "../Container";
import Heading from "../Heading";
import ModuleSpacing from "../ModuleSpacing";

import styles from "./Quote.module.css";
import { trimParagraphs } from "../../globals/helpers";

const Quote = ({ background_color, quote_block }) => {
	const [currentQuoteSlide, setCurrentQuoteSlide] = useState(0);
	const [quoteSliderRef, quoteSlider] = useKeenSlider({
		initial: 0,
		slidesPerView: 1,
		mode: "snap",
		autoHeight: true,
		slideChanged(s) {
			setCurrentQuoteSlide(s.details().relativeSlide);
		}
	});
	return (
		<section className={`${styles.quote} ${styles[`background${background_color}`]}`}>
			<Container>
				<ModuleSpacing top>
					<div className={styles.quoteCarouselContainer} ref={quoteSliderRef}>
						{quote_block?.length && quote_block.map((item, index) => (
							<div key={index} className="keen-slider__slide">
								<span className={styles.quoteMarks} />
								{item.quote && <Heading type="h3" text={trimParagraphs(item.quote)} />}
								{item?.person && (
									<div className={styles.credit}>
										{item?.image?.localFile?.url && (
										<div className={styles.picture}>
											<img src={item.image.localFile.url} alt={item.image.alt_text} />
										</div>
										)}
										<div className={styles.name}>
											<strong>{item.person}</strong>
											<span>{item.position}</span>
										</div>
									</div>
								)}
							</div>
						))}
					</div>
					{quoteSlider && quote_block?.length > 1 && (
						<div className={styles.dots}>
						{[...Array(quote_block.length)].map((_, index) => (
								<button
									key={index}
									onClick={() => {
										quoteSlider.moveToSlideRelative(index);
									}}
									className={styles[`${(currentQuoteSlide === index ? "dotActive" : "dot")}`]}
								/>
							)
						)}
						</div>
					)}
				</ModuleSpacing>
			</Container>
		</section>
	);
};

export default Quote;

export const query = graphql`
	fragment QuoteFragment on WordPressAcf_quote {
		background_color
		quote_block {
			image {
				alt_text
				localFile {
					url
				}
			}
			person
			position
			quote
		}
  }
`;