import React, { useState, forwardRef } from "react";
import { graphql, Link } from "gatsby";
import { useSpring, animated } from "react-spring";
import { Modal, Backdrop } from '@material-ui/core';

import CloseButton from "../../resources/assets/close-button.svg";

import Container from "../Container";
import Heading from "../Heading";
import ModuleSpacing from "../ModuleSpacing";

import LinkedIn from "../../resources/assets/linkedin.svg";

import styles from "./BioBlock.module.css";
import { trimParagraphs } from "../../globals/helpers";

const Fade = forwardRef((props, ref) => {
	const { in: open, children, onEnter, onExited, ...other } = props;
	const style = useSpring({
	  from: { height: "100vh", opacity: 0, transform: "translate3d(100%,0,0)" },
	  to: { opacity: open ? 1 : 0, transform: `translate3d(${typeof window !== 'undefined' && window.innerWidth > 576 ? "10%" : "0"},0,0)` },
	  onStart: () => {
		if (open && onEnter) {
		  onEnter();
		}
	  },
	  onRest: () => {
		if (!open && onExited) {
		  onExited();
		}
	  },
	});
  
	return (
	  <animated.div ref={ref} style={style} {...other}>
		{children}
	  </animated.div>
	);
});

const BioBlock = ({ headline, person }) => {
	const [ showModal, setShowModal ] = useState(false);
	const [ loadedBio, setLoadedBio ] = useState(-1);
	return (
		<section className={styles.bioBlock}>
			<Container>
				<ModuleSpacing top>
					{headline && <Heading type="h3" text={trimParagraphs(headline)} />}
					<div className={styles.blocks}>
						{person?.length  && person.map((item, index) => (
							<div key={index} className={styles.block}>
								{item?.image?.localFile?.url && <img src={item.image.localFile.url} alt={item.image.alt_text} />}
								<div className={styles.heading}>
									{item?.name && <strong>{item.name}</strong>}
									{item.biography && <button onClick={() => { setShowModal(true); setLoadedBio(index); }}>Bio</button>}
								</div>
								{item.position && <span>{item.position}</span>}
							</div>
						))}
					</div>
					<Modal
						className={styles.modal}
						open={showModal}
						onClose={() => setShowModal(false)}
						closeAfterTransition
						BackdropComponent={Backdrop}
						BackdropProps={{
							timeout: 500,
						}}
					>
						<Fade in={showModal} className={styles.modalOuter}>
							<div className={styles.inner}>
								<Container>
									<ModuleSpacing top>
										{(loadedBio !== -1) && (
											<div className={styles.modalContainer}>
												<button className={styles.closeButton} onClick={() => setShowModal(false)}><CloseButton /></button>
												<div className={styles.modalContent}>
													<div className={styles.imageBlock}><img src={person[loadedBio].image.localFile.url} alt={person[loadedBio].image.alt_text} /></div>
													<div className={styles.bio}>
														<Heading type="h3">{person[loadedBio].name}</Heading>
														<p>{person[loadedBio].position}</p>
														<div dangerouslySetInnerHTML={{ __html: person[loadedBio].biography}} />
														{person[loadedBio].linkedin_link && <Link to={person[loadedBio].linkedin_link} className={styles.linkedIn} target="_blank"><LinkedIn /></Link>}
													</div>
												</div>
											</div>
										)}
									</ModuleSpacing>
								</Container>
							</div>
						</Fade>
					</Modal>
				</ModuleSpacing>
			</Container>
		</section>
	);
};

export default BioBlock;

export const query = graphql`
	fragment BioBlockFragment on WordPressAcf_bio_block {
		headline
		person {
			image {
				alt_text
				localFile {
					url
				}
			}
			name
			position
			biography
			linkedin_link
		}
	}
`;