import React from "react";
import { graphql } from "gatsby";

import Container from "../Container";
import Cta from "../Cta";
import Heading from "../Heading";
import ModuleSpacing from "../ModuleSpacing";

import styles from "./CtaBlock.module.css";
import { trimParagraphs } from "../../globals/helpers";

const CtaBlock = ({ cta, headline = "", type, form_snippet }) => (
	<section className={styles.ctaBlock}>
		<Container>
			<ModuleSpacing top>
				<div className={styles.innerContainer}>
					<Heading type="h3" text={trimParagraphs(headline)} />
					{type === "cta" && <Cta href={cta.url} target={cta.target}>{cta.title}</Cta>}
					{type === "input" && <div dangerouslySetInnerHTML={{ __html: form_snippet }} />}
				</div>
			</ModuleSpacing>
		</Container>
	</section>
);

export default CtaBlock;

export const query = graphql`
	fragment CtaBlockFragment on WordPressAcf_cta_block {
		cta {
			target
			title
			url
		}
		headline
		type
		form_snippet
  }
`;