import React, { useState } from "react";
import { Link, graphql } from "gatsby";
import { useKeenSlider } from "keen-slider/react";

import Container from "../Container";
import Cta from "../Cta";
import Eyebrow from "../Eyebrow";
import Heading from "../Heading";
import ModuleSpacing from "../ModuleSpacing";

import ArrowIcon from "../../resources/assets/arrow.svg"

import { trimParagraphs } from "../../globals/helpers";

import styles from "./ImageStack.module.css";

const ImageStack = ({ copy, cta, eyebrow, grid, headline, headline_size = "small" }) => {
	const [currentImageSlide, setCurrentImageSlide] = useState(0);
	const [imageSliderRef, imageSlider] = useKeenSlider({
		initial: 0,
		slidesPerView: 1,
		mode: "snap",
		autoHeight: true,
		slideChanged(s) {
			setCurrentImageSlide(s.details().relativeSlide);
		}
	});
	return (
		<section className={styles.imageStack}>
			<Container>
				<ModuleSpacing top>
					{eyebrow && <div className={styles.eyebrow}><Eyebrow>{eyebrow}</Eyebrow></div>}
					{headline && <Heading type="h2" text={trimParagraphs(headline)} />}
					{copy && <div className={styles.leadParagraph} dangerouslySetInnerHTML={{ __html: copy }} />}
					{cta?.url && <Cta href={cta.url} target={cta.target}>{cta.title}</Cta>}
					{grid && grid.length && 
						typeof window !== 'undefined' && window.innerWidth > 640 ? 
							(
								<ul className={`${headline ? styles.gridWithPadding: styles.grid} ${(grid.length % 3 == 0) ? styles.threeUp : styles.fourUp}`}>
									{grid?.length && grid.map((item, index) => (
										<li key={index}>
											{item?.image?.localFile?.url && <div className={headline_size === "large" ? styles.largeImageContainer : styles.smallImageContainer}><img src={item.image.localFile.url} alt={item.image.alt_text} /></div>}
											<strong className={headline_size === "large" ? styles.headlinelarge : styles.headlinesmall}>{item.subheading}</strong>
											<p dangerouslySetInnerHTML={{ __html: item.copy }} />
											{item?.cta?.url && <Link to={item.cta.url} target={item.cta.target} className={styles.readMore}>{item.cta.title}<ArrowIcon /></Link>}
										</li>
									))}
								</ul>
							)
							:
							(
								<>
									<div className={styles.imageCarouselContainer} ref={imageSliderRef}>
										{grid?.length && grid.map((item, index) => (
											<li key={index} className={`${styles.slide} keen-slider__slide`}>
												{item?.image?.localFile?.url && <div className={headline_size === "large" ? styles.largeImageContainer : styles.smallImageContainer}><img src={item.image.localFile.url} alt={item.image.alt_text} /></div>}
												<strong className={headline_size === "large" ? styles.headlinelarge : styles.headlinesmall}>{item.subheading}</strong>
												<p dangerouslySetInnerHTML={{ __html: item.copy }} />
												{item?.cta?.url && <Link to={item.cta.url} target={item.cta.target} className={styles.readMore}>{item.cta.title}<ArrowIcon /></Link>}
											</li>
										))}
									</div>
									{imageSlider && grid?.length > 1 && (
									<div className={styles.imageDots}>
									{[...Array(grid.length)].map((_, index) => (
											<button
												key={index}
												onClick={() => {
													imageSlider.moveToSlideRelative(index);
												}}
												className={styles[`${(currentImageSlide === index ? "dotActive" : "dot")}`]}
											/>
										)
									)}
									</div>
								)}
								</>
							)
					}
				</ModuleSpacing>
			</Container>
		</section>
	);
};

export default ImageStack;

export const query = graphql`
	fragment ImageStackFragment on WordPressAcf_image_stack {
		copy
		cta {
			target
			title
			url
		}
		eyebrow
		grid {
			copy
			image {
				alt_text
				localFile {
					url
				}
			}
			subheading
			cta {
				target
				title
				url
			}
		}
		headline
		headline_size
		id
	}
`;