import React, { useState } from "react";
import { graphql } from "gatsby";
import { useKeenSlider } from "keen-slider/react";

import Container from "../Container";
import ModuleSpacing from "../ModuleSpacing";
import Heading from "../Heading";

import { trimParagraphs } from "../../globals/helpers";

import styles from "./LogoGrid.module.css";

const LogoGrid = ({ background_color, headline, images }) => {
	const [currentImageSlide, setCurrentImageSlide] = useState(0);
	const [imageSliderRef, imageSlider] = useKeenSlider({
		initial: 0,
		slidesPerView: 1,
		mode: "snap",
		autoHeight: true,
		slideChanged(s) {
			setCurrentImageSlide(s.details().relativeSlide);
		}
	});
	const originalState = [...images];
	const imagesPerSection = 8;
	const splitArray = new Array(Math.ceil(originalState.length / imagesPerSection)).fill().map(_ => originalState.splice(0, imagesPerSection));
	return (
		<section className={`${styles.logoGrid} ${styles[`background${background_color}`]}`}>
			<Container>
				<ModuleSpacing top>
					<div className={styles.headline}><Heading type="h4" text={trimParagraphs(headline)} /></div>
					{splitArray && (
						<>
							<ul className={styles.grid} ref={imageSliderRef}>
								{splitArray.map((section, index) => (
									<li key={index} className={`${styles.slide} keen-slider__slide`}>
										{section.map((image, index) => (
											<div className={styles.imageContainer} key={index}><img src={image?.localFile?.url} alt={image.alt_text} /></div>
										))}
									</li>
								))}
							</ul>
							{splitArray.length > 1 && (
								<div className={styles.imageDots}>
									{splitArray.map((_, index) => (
											<button
												key={index}
												onClick={() => {
													imageSlider.moveToSlideRelative(index);
												}}
												className={styles[`${(currentImageSlide === index ? "dotActive" : "dot")}`]}
											/>
										)
									)}
								</div>
							)}
						</>
					)}
				</ModuleSpacing>
			</Container>
		</section>
	);
};

export default LogoGrid;

export const query = graphql`
	fragment LogoGridFragment on WordPressAcf_logo_grid {
		background_color
		headline
		images {
			alt_text
			localFile {
				url
			}
		}
		id
	}
`;