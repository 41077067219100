import React from "react";
import { graphql } from "gatsby";
import Container from "../Container";
import Heading from "../Heading";
import ModuleSpacing from "../ModuleSpacing";

import styles from "./MediaBlock.module.css";
import { trimParagraphs } from "../../globals/helpers";

const MediaBlock = ({ headline, copy, media_block }) => (
	<section className={styles.mediaBlock}>
		<Container>
			<ModuleSpacing top>
				{headline && <Heading type="h4" text={trimParagraphs(headline)} />}
				{copy && <div dangerouslySetInnerHTML={{ __html: copy }} />}
				<div className={styles.blocks}>
					{media_block?.length && media_block.map((block, index) => (
						<div key={index} className={styles.block}>
							{block?.video && block.video.media_type === "file" ? (
								block.video?.localFile && <video controls>
									<source src={block.video.localFile.url} />
								</video>
							)
							: (
								block?.video && block.video?.localFile && <img src={block.video.localFile.url} />
							)}
							{block.copy && <div className={styles.copyBlock} dangerouslySetInnerHTML={{ __html: block.copy }} />}
						</div>
					))}
				</div>
			</ModuleSpacing>
		</Container>
	</section>
);

export default MediaBlock;

export const query = graphql`
	fragment MediaBlockFragment on WordPressAcf_media_block {
		headline
		copy
		media_block {
			copy
			video {
				localFile {
					url
				}
				media_type
			}
		}
  }
`;