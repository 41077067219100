import React from "react";
import { graphql } from "gatsby";

import Container from "../Container";
import Eyebrow from "../Eyebrow";
import Heading from "../Heading";
import ModuleSpacing from "../ModuleSpacing";

import styles from "./SimpleText.module.css";
import { trimParagraphs } from "../../globals/helpers";

const SimpleText = ({ eyebrow, background_color, headline, copy }) => (
	<section className={`${styles.simpleBlock} ${styles[`background${background_color}`]} post`}>
		<Container>
			<ModuleSpacing top>
				{eyebrow && <Eyebrow>{eyebrow}</Eyebrow>}
				{headline && <Heading type="h3" text={trimParagraphs(headline)} />}
				{copy && <div dangerouslySetInnerHTML={{ __html: copy }} />}
			</ModuleSpacing>
		</Container>
	</section>
);

export default SimpleText;

export const query = graphql`
	fragment SimpleTextFragment on WordPressAcf_simple_text {
		background_color
		eyebrow
		headline
		copy
	}
`;