import React from "react";
import { graphql } from "gatsby";

import Container from "../Container";
import Eyebrow from "../Eyebrow";
import Heading from "../Heading";
import ModuleSpacing from "../ModuleSpacing";

import styles from "./TextBlock.module.css";
import { trimParagraphs } from "../../globals/helpers";

const TextBlock = ({ eyebrow, background_color, headline, copy, block }) => (
	<section className={`${styles.textBlock} ${styles[`background${background_color}`]}`}>
		<Container>
			<ModuleSpacing top>
				{eyebrow && <Eyebrow>{eyebrow}</Eyebrow>}
				{headline && <Heading type="h3" text={trimParagraphs(headline)} />}
				{copy && <div className={styles.leadParagraph} dangerouslySetInnerHTML={{ __html: copy }} />}
				<div className={`${!headline ? styles.blocks : styles.blocksWithPadding} ${(block?.length % 3 == 0) ? styles.threeUp : styles.twoUp}`}>
					{block?.length  && block.map((block, index) => (
						<div key={index} className={styles.block}>
							{block?.headline && <Heading type="h5" text={trimParagraphs(block.headline)} />}
							{block?.copy && <div dangerouslySetInnerHTML={{ __html: block.copy }} />}
						</div>
					))}
				</div>
			</ModuleSpacing>
		</Container>
	</section>
);

export default TextBlock;

export const query = graphql`
	fragment TextBlockFragment on WordPressAcf_text_block {
		background_color
		eyebrow
		headline
		copy
		block {
			headline
			copy
		}
	}
`;